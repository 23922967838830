import Carousel from "../Carousel/Carousel";
import CategoryContainer from "../Category/CategoryContainer";

const Home = () =>{
    return(
        <>
        <Carousel/>
        <CategoryContainer/>
        </>
    )
}
export default Home;